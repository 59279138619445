import React from 'react'
import ProjectList from './ProjectList'
import Button from './Button'
import { Parallax } from 'react-scroll-parallax'
import Img from 'gatsby-image'

const ProjectSections = ({ node }) => {
  return (
    <ProjectList backgroundImg={node.backgroundImage}>
      <div className="column center-content is-one-third">
        <h1 className="project-title">{node.title}</h1>
        <h2 className="project-type">{node.type || 'Project Type'}</h2>
        <Button to={'/projects/' + node.slug} color="dark" text="More info" />
      </div>
      <Parallax className="column image-column" offsetYMax={40} offsetYMin={0}>
        <div className="column image-column">
          <Img
            imgStyle={{ objectFit: 'contain' }}
            style={{ overflow: 'visible' }}
            backgroundColor={'transparent'}
            className="project-image"
            sizes={node.mockup.sizes}
            alt={node.title + ' project image'}
          />
        </div>
      </Parallax>
    </ProjectList>
  )
}

export default ProjectSections

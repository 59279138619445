import React, { useState } from 'react'
import Waypoint from 'react-waypoint'
import classNames from 'classnames'
import _ from 'lodash'

const WaypointWrapper = props => {
  const [inView, setInView] = useState(false)

  const slideReveal = () => {
    setInView(true)
  }

  const _slideDebounced = _.debounce(slideReveal, 500)

  let ReachStyles = classNames({
    reach: inView,
    section: true,
    project_section: true,
  })

  return (
    <Waypoint topOffset="100px" onEnter={_slideDebounced}>
      <div className={ReachStyles}>{props.children}</div>
    </Waypoint>
  )
}

export default WaypointWrapper

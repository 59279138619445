import React from 'react'
import WaypointWrapper from './WaypointWrapper'
import Img from 'gatsby-image'

const ProjectList = props => {
  return (
    <WaypointWrapper>
      <div className="illu illu-right">
        <Img
          backgroundColor={'transparent'}
          outerWrapperClassName="bg bg-right"
          alt="project bg background"
          style={{ width: '100%', height: '100%', position: 'absolute' }}
          sizes={props.backgroundImg.sizes}
        />
      </div>
      <div className="container">
        <div className="columns reverse-column-order">{props.children}</div>
      </div>
    </WaypointWrapper>
  )
}

export default ProjectList
